<template>
  <!-- 进项发票管理 -->
  <div
    class="bg-white px-3 mt-5"
    style="margin: -20px; margin-top: 1rem; margin-bottom: 0 !important"
  >
    <div class="d-flex align-items-center">
      <!-- 左边 -->
      <router-link :to="{ name: 'invoice_inputinvoice_create' }">
        <el-button type="success" size="mini">添加发票</el-button>
      </router-link>
      <!-- 右边 -->
      <div class="ml-auto" v-show="!superSearch">
        <el-input
          v-model="form.name"
          placheholder="要搜索的合同名称"
          size="mini"
          style="width: 150px"
          class="mr-2"
        ></el-input>
        <el-button type="info" size="mini">搜索</el-button>
        <el-button size="mini" @click="superSearch = true">高级搜索</el-button>
      </div>
    </div>
    <!-- 高级搜索块 -->
    <el-card class="box-card my-2" v-show="superSearch">
      <template #header>
        <div style="margin: -8px">
          <span>高级搜索</span>
          <el-button
            class="button"
            type="text"
            style="float: right; padding: 3px 0"
            @click="superSearch = false"
            >收起</el-button
          >
        </div>
      </template>
      <el-form inline ref="form" :model="form" label-width="80px">
        <el-form-item label="合同名称" class="mb-0">
          <el-input v-model="form.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="供应商名称" class="mb-0">
          <el-input v-model="form.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="成本类别" class="mb-0">
          <el-input v-model="form.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="info" size="mini">搜索</el-button>
          <el-button size="mini">清空筛选条件</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-table border class="mt-3" :data="tableData" style="width: 100%" 
              :show-summary="true"
              :summary-method="summaryMethod">
      <el-table-column
        prop="billing_date"
        label="日期"
        width="100"
        align="center"
      />
      <el-table-column
        prop="tax_class"
        label="发票种类"
        width="100"
        align="center"
        :filters="[
          { text: '普票', value: '1' },
          { text: '专票', value: '2' },
        ]"
        :filter-method="filterHandler"
        ><template #default="scope">
          {{ scope.row.tax_class == 1 ? "普票" : "专票" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="invoice_no"
        label="发票号码"
        width="100"
        align="center"
      />
      <el-table-column
        prop="supplier_name"
        label="供应商名称"
        width="300"
        align="center"
        :filters="supplierfilters"
        :filter-method="filterHandler"
      />
      <el-table-column
        prop="amount_money"
        label="金额"
        width="150"
        align="right"
        :formatter="toThousands"
      />
      <el-table-column
        prop="tax_amount"
        label="税额"
        width="150"
        align="right"
        :formatter="toThousands"
      />
      <el-table-column
        prop="total_price_tax"
        label="价税合计"
        width="150"
        align="right"
        :formatter="toThousands"
      />
      <el-table-column prop="tax_rate" label="税率" width="80" align="center">
        <template #default="scope">
          {{ scope.row.tax_rate + "%" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_naem"
        label="货品名称"
        width="180"
        align="center"
      />
      <el-table-column
        prop="category_id"
        label="成本类别"
        width="150"
        align="center"
      />
       <el-table-column
        prop="buys_name"
        label="购买方"
        width="150"
        align="center"
      />
      <el-table-column label="操作" width="240" align="center">
        <template #default="scope">
          <el-button-group>
            <el-button
              size="small"
              plain
              @click="handleEdit(scope.$index, scope.row)"
              >修改</el-button
            >
            <el-button size="small" plain @click="pdfview(scope.row.id)"
              >附件</el-button
            >
            <el-button
              size="small"
              plain
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 60px"></div>
    <!-- 占位底部60PX -->
    <el-footer
      class="border-top d-flex align-items-center px-0 position-fixed bg-white"
      style="bottom: 0; left: 150px; right: 0; z-index: 100"
      ><el-pagination
        v-model:currentPage="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="[5, 10, 100, 500]"
        layout="sizes, prev, pager, next,total,jumper"
        :total="lotalrows"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination
    ></el-footer>
  </div>
  <!-- 查看PDF文件List -->
  <el-dialog v-model="pdfurlVisible" width="100%" fullscreen>
    <pdf-view fileclass="3" :prdclassid="prdclassid"></pdf-view>
  </el-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      //分类数据
      pageSize: 10, //每页数据 默认10条
      currentPage: 1, //当前页码  默认第1页
      lotalrows: 0, //总行数

      prdclassid: "", //查看附件记录的ID
      pdfurlVisible: false, //查看附件列表开关

      superSearch: false,
      form: {
        name: "",
      },
      tableData: [],
      //供应商名称筛选过滤
      supplierfilters:[]
    };
  },
  created() {
    this.loadfcontractinfo(this.pageSize, this.currentPage);
  },
  methods: {
    //选择页码选项
    handleCurrentChange(val) {
      console.log("选择页码选项", val);
      this.currentPage = val;
      this.loadfcontractinfo(this.pageSize, this.currentPage);
    },
    //选择每页条数
    handleSizeChange(val) {
      console.log("选择每页条数", val);
      this.pageSize = val;
      this.loadfcontractinfo(this.pageSize, this.currentPage);
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    loadfcontractinfo(rows, pags) {
      axios
        .get("admin/InputInvoice", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
            page: pags,
            list_rows: rows,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******99", response.data.data, "99******");
          (this.currentPage = response.data.current_page), //当前页码  默认第1页
            (this.lotalrows = response.data.total), //总行数
            (this.tableData = response.data.data);

            this.supplierfilters = this.arrayinfdexof(response.data.data)
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //查看pdf清单列表
    pdfview(vals) {
      console.log("*********qwqwqwqw", vals);
      this.prdclassid = vals;
      this.pdfurlVisible = true;
    },
    //格式化数字
    toThousands(row, column, cellValue) {
      return cellValue.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
    },
    //表格筛选方法
    filterHandler(val, row, column) {
      const property = column["property"];
      return row[property] === val;
    },
    //数据去重复  返回数组
    //:filters="[
    //      { text: '普票', value: '1' },
    //      { text: '专票', value: '2' },
    //    ]"
    arrayinfdexof(prosarr){
      let arr1 = []
      let arr = []
      for(let i=0;i<prosarr.length;i++){
        if(arr.indexOf(prosarr[i]["supplier_id"])===-1){
          arr.push(prosarr[i]["supplier_id"])
          let objtemp = new Object()
          objtemp.value = prosarr[i]["supplier_name"]
          objtemp.text = prosarr[i]["supplier_name"]
          arr1.push(objtemp)
        }
      }
      return arr1
      },
       //合计行
    summaryMethod(param) {
      let arr = [];
      let totalsum4 = 0;
      let totalsum5 = 0;
      let totalsum6 = 0;
      param.data.forEach((element) => {
        totalsum4 += element.amount_money;
        totalsum5 += element.tax_amount;
        totalsum6 += element.total_price_tax;
      });

      arr[0] = "合计";
      arr[4] = totalsum4.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
      arr[5] = totalsum5.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
      arr[6] = totalsum6.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
      return arr;
    },


  },
};
</script>
<style scoped>
.el-table__footer-wrapper .cell{
  text-align: right;
  font-size: 15px;
  font-weight: bold;
}
</style>
